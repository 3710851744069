import styled from "styled-components";
import dp from "../../images/dp_placeholder.png";

const StyledRoutineCard = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: black;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  margin-top: 10px;
  min-width: 250px;
  margin-right: 10px;
`;

const ProfilePicture = styled.div`
  & > img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }
`;

const Username = styled.span`
  font-size: 11px;
  font-weight: 600;
  padding-left: 5px;
`;

const ListText = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 20px;
  overflow: hidden;
  margin: 15px 0px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const ProductCount = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #1c1c1e;
`;

const ReviewCard = ({ list, user_id }) => {
  return (
    <StyledRoutineCard href={`/?listId=${list?.id}&currentUserId=${user_id}`}>
      <ProfilePicture>
        <img src={list?.user?.image_url || dp} alt="no img" />
        <Username>{list?.user?.username}</Username>
      </ProfilePicture>
      <ListText>{list?.description}</ListText>
      <ProductCount>{list?.product_count} Products</ProductCount>
    </StyledRoutineCard>
  );
};

export default ReviewCard;
