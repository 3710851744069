import React, { useEffect } from "react";
import styled from "styled-components";
import ProductCard from "../common/ProductCard";
import { useParams } from "react-router-dom";
import useFetchProductByBrandQuery from "../../hooks/useFetchProductByBrandQuery";
import { Loading } from "../common/Loading";
import { createMetaTag, setTitle } from "../common/utils";
import { Breadcrumb, HomeLink, BrandLink } from "../common/BreadCrumb";
import ReactGA from "react-ga4";
import default_brand from "../../images/default_brand.png";
import { AiOutlineLink } from "react-icons/ai";

const StyledContainer = styled.div``;

const InnerContainer = styled.div`
  border-radius: 5px;
`;

const StyledHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  background-color: #fff;
  padding: 20px;
`;

const AssociatedBrand = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding: 20px;
  text-align: center;
`;

const BrandImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-bottom: 25px;
`;

const BrandName = styled.div`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 25px;
`;

const BrandDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  max-width: 500px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const BrandLinks = styled.div`
  display: flex;
  flex-direction: row;
`;

const BrandLinkItem = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
`;

const BrandButtonContainer = styled.div`
  margin-top: 20px;
`;

const BrandButton = styled.button`
  font-size: 14px;
  font-weight: 500;
  padding: 2px 20px;
  border-radius: 25px;
  background-color: ${({ outline }) => (!outline ? "#ff2d55" : "white")};
  color: ${({ outline }) => (outline ? "#ff2d55" : "white")};
  border: 1px solid #ff2d55;
  margin-right: 10px;
  cursor: pointer;
`;

const ReviewSection = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 49.5%;

  & > div :nth-child(4) > p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`;

const Product = () => {
  const { brand_id, user_id } = useParams();

  const { data: { data: products = [] } = { data: [] }, isLoading } =
    useFetchProductByBrandQuery({
      brand_id,
      limit: 10,
    });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Brand page",
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!products.length) {
      return;
    }

    setTitle(
      `Iris | ${products?.[0]?.brand?.name}: ${products.length} - reviews, routines, and more`
    );
    const description = `
      Iris | ${products?.[0]?.brand?.name}: ${products.length} - Iris is a beauty community with non-sponsored reviews, routines, Q&As, Giveaways, and Drops`;

    createMetaTag({
      name: "description",
      content: description,
    });
  }, [products]);

  if (isLoading) {
    return <Loading>Loading...</Loading>;
  }

  return (
    <StyledContainer>
      <Breadcrumb>
        <HomeLink to="/">Home</HomeLink>
        {">"}
        <BrandLink to={`/brand/${brand_id}/user/${user_id}`}>
          {products?.[0]?.brand?.name}
        </BrandLink>
      </Breadcrumb>
      <InnerContainer>
        {products?.[0]?.brand?.user_id ? (
          <AssociatedBrand>
            <BrandImage src={default_brand}></BrandImage>
            <BrandName>{products?.[0]?.brand?.name}</BrandName>
            <BrandDescription>
              lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </BrandDescription>
            <BrandLinks>
              <BrandLinkItem>
                <AiOutlineLink />
                link 1
              </BrandLinkItem>
              <BrandLinkItem>
                <AiOutlineLink />
                link 2
              </BrandLinkItem>
              <BrandLinkItem>
                <AiOutlineLink />
                link 3
              </BrandLinkItem>
            </BrandLinks>
            <BrandButtonContainer>
              <BrandButton>Follow</BrandButton>
              <BrandButton outline>Official Account</BrandButton>
            </BrandButtonContainer>
          </AssociatedBrand>
        ) : (
          <StyledHeader>{products?.[0]?.brand?.name}</StyledHeader>
        )}
        <ReviewSection>
          {products.map((product) => (
            <ReviewContainer key={product.id}>
              <ProductCard productData={product} user_id={user_id} />
            </ReviewContainer>
          ))}
        </ReviewSection>
      </InnerContainer>
    </StyledContainer>
  );
};

export default Product;
