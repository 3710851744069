import { useParams, useLocation, Link } from "react-router-dom";
import React, { useEffect } from "react";
import styled from "styled-components";
import ReactGA from "react-ga4";
import ReviewCard from "./ReviewCard";
import ProductCard from "../common/ProductCard";
import useFetchProductQuery from "../../hooks/useFetchProductQuery";
import useFetchProductReviewsQuery from "../../hooks/useFetchProductReviewsQuery";
import { Loading } from "../common/Loading";
import {
  Breadcrumb,
  HomeLink,
  BrandLink,
  ProductLink,
  ReviewTypeLink,
} from "../common/BreadCrumb";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  border-radius: 5px;
`;

const StyledHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  background-color: #fff;
  padding: 20px;
`;

const ReviewSection = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 10px;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0px;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Product = () => {
  const { product_id, user_id } = useParams();
  const location = useLocation();
  const reviews_type =
    new URLSearchParams(location.search).get("reviews_type") || "All Reviews";

  const { data: productData, isLoading: isProductLoading } =
    useFetchProductQuery({
      product_id,
      user_id,
    });
  const { data: { data: reviews } = { data: [] }, isLoading: isReviewLoading } =
    useFetchProductReviewsQuery({
      product_id,
      limit: 100,
    });

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Review page",
    });
  }, []);

  if (isProductLoading || isReviewLoading) {
    return <Loading>Loading...</Loading>;
  }

  return (
    <StyledContainer>
      <Breadcrumb>
        <HomeLink>Home</HomeLink>
        {">"}
        <BrandLink to={`/brand/${productData?.brand?.id}/user/${user_id}`}>
          {productData?.brand?.name}
        </BrandLink>
        {">"}
        <ProductLink to={`/product/${productData?.id}/user/${user_id}`}>
          {productData?.name}
        </ProductLink>
        {">"}
        <ReviewTypeLink>{reviews_type}</ReviewTypeLink>
      </Breadcrumb>
      <InnerContainer>
        <StyledHeader>{reviews_type}</StyledHeader>
        <ReviewSection>
          <ReviewContainer>
            {reviews?.map((review) => (
              <ReviewCard
                key={review.id}
                review={review}
                productVariants={productData?.product_variants}
                isVariantEnabled={productData?.variant_enabled}
              />
            ))}
          </ReviewContainer>
          <ProductContainer>
            <ProductCard productData={productData} user_id={user_id} />
          </ProductContainer>
        </ReviewSection>
      </InnerContainer>
    </StyledContainer>
  );
};

export default Product;
