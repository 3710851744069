import axios from "axios";
import { useQuery } from "react-query";
import { AUTH_TOKEN } from "../constants/url";

const useFetchuseFetchTopHolyGrailsQuery = ({ current_user_id }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchTask = async () => {
    const tasks = await axios.get(
      `${apiUrl}/products/fetch_top_holy_grails?current_user_id=${current_user_id}&filter_by_user=true`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": AUTH_TOKEN,
        },
      }
    );

    return tasks?.data;
  };
  const { data, isLoading, refetch } = useQuery("useFetchTopHolyGrails", () =>
    fetchTask()
  );
  return {
    data,
    isLoading,
    refetch,
  };
};
export default useFetchuseFetchTopHolyGrailsQuery;
