import styled from "styled-components";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { getAvg } from "../common/utils";

const StyledReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  margin-top: 10px;
  background-color: white;
  min-height: 450px;

  @media (max-width: 768px) {
    padding: 10px;
    min-height: 380px;
  }
`;

const RatingRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 11px;

  @media (max-width: 768px) {
    font-size: 8px;
  }
`;

const RatingColumn = styled.div`
  margin-right: 10px;
`;

const ProductText = styled.div`
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const BrandName = styled(Link)`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: darkblue;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ProductName = styled(Link)`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: black;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &:hover {
    color: darkblue;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ProductPicture = styled.div`
  & > img {
    width: 100px;
    height: 100px;
  }
`;

const ReviewCard = ({ productData, user_id }) => {
  return (
    <StyledReviewCard>
      <BrandName to={`/brand/${productData?.brand?.id}/user/${user_id}`}>
        {productData?.brand?.name}
      </BrandName>
      <ProductName to={`/product/${productData?.id}/user/${user_id}`}>
        {productData?.name}
      </ProductName>
      <RatingRow>
        <RatingColumn>
          <AiFillStar color="#ff2d55" />{" "}
          {productData?.rating_counts
            ? getAvg(productData?.rating_counts)
            : productData?.avg_rating}
        </RatingColumn>
        <RatingColumn>{productData?.review_count} Reviews</RatingColumn>
        <RatingColumn>{productData?.holy_grail_count} Holy Grails</RatingColumn>
      </RatingRow>
      <ProductText>
        <p>{productData.description}</p>
        <p>{productData.ingredients}</p>
      </ProductText>
      <ProductPicture>
        <img src={productData?.images?.[0]} alt="no img" />
      </ProductPicture>
    </StyledReviewCard>
  );
};

export default ReviewCard;
