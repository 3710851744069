import axios from "axios";
import { useQuery } from "react-query";
import { AUTH_TOKEN } from "../constants/url";

const useFetchBrandsQuery = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchTask = async () => {
    const tasks = await axios.get(`${apiUrl}/brands/fetch_all`, {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": AUTH_TOKEN,
      },
    });

    return tasks?.data;
  };
  const { data, isLoading, refetch } = useQuery("useFetchBrands", () =>
    fetchTask()
  );
  return {
    data,
    isLoading,
    refetch,
  };
};
export default useFetchBrandsQuery;
