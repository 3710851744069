import axios from "axios";
import { useQuery } from "react-query";
import { AUTH_TOKEN } from "../constants/url";

const useFetchRandomBrandsQuery = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchTask = async () => {
    const tasks = await axios.get(
      `${apiUrl}/brands/fetch_random?items_to_return=20`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": AUTH_TOKEN,
        },
      }
    );

    return tasks?.data;
  };
  const { data, isLoading, refetch } = useQuery("useFetchRandomBrands", () =>
    fetchTask()
  );
  return {
    data,
    isLoading,
    refetch,
  };
};
export default useFetchRandomBrandsQuery;
