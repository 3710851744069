import axios from "axios";
import { useQuery } from "react-query";

const useFetchProductByBrandQuery = ({ brand_id, limit }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchTask = async () => {
    const tasks = await axios.get(
      `${apiUrl}/products/brand/${brand_id}?limit=${limit}`
    );
    return tasks?.data;
  };
  const { data, isLoading, refetch } = useQuery(
    "useFetchProductByBrandQuery",
    () => fetchTask()
  );
  return {
    data,
    isLoading,
    refetch,
  };
};
export default useFetchProductByBrandQuery;
