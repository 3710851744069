export const getCountNumber = (count) => {
  if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}K`;
  } else {
    return count.toString();
  }
};

export const getAvg = (ratingCounts) => {
  let totalStars = 0;
  let totalCount = 0;

  for (let star in ratingCounts) {
    let starCount = parseInt(star);
    let count = ratingCounts[star];

    totalStars += starCount * count;
    totalCount += count;
  }
  let averageStars = totalStars / totalCount;
  return Number(averageStars).toFixed(1);
};

export const createMetaTag = ({ name, content }) => {
  const existingMetaTag = document.querySelector(`meta[name="${name}"]`);
  if (existingMetaTag) {
    return;
  }
  const metaTag = document.createElement("meta");
  metaTag.name = name;
  metaTag.content = content;
  document.head.appendChild(metaTag);
};

export const setTitle = (title) => {
  document.title = title;
};
