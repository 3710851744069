import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import MobileLayout from "./layouts/mobile";
import MainLayout from "./layouts/main";
import RoutineAndList from "./components/RoutineAndList";
import Product from "./components/Product";
import Review from "./components/Review";
import Brand from "./components/Brand";
import BrandList from "./components/BrandList";
import { QueryClientProvider, QueryClient } from "react-query";
import ReactGA from "react-ga4";

ReactGA.initialize("G-10Q3JEMHV1");
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryParameters = new URLSearchParams(window.location.search);
const routineId = queryParameters.get("routineId");
const list = queryParameters.get("listId");
root.render(
  <React.StrictMode>
    <QueryClientProvider client={new QueryClient()}>
      <BrowserRouter>
        <Routes>
          <Route
            path=""
            element={routineId || list ? <MobileLayout /> : <MainLayout />}
          >
            <Route path="" element={<RoutineAndList />} />
          </Route>
          <Route path="" element={<MainLayout />}>
            <Route
              path="product/:product_id/user/:user_id"
              element={<Product />}
            />
            <Route
              path="reviews/:product_id/user/:user_id"
              element={<Review />}
            />
            <Route path="brand/:brand_id/user/:user_id" element={<Brand />} />
            <Route path="brands" element={<BrandList />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
