import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useFetchBrandsQuery from "../../hooks/useFetchBrandsQuery";
import { Loading } from "../common/Loading";
import { AiOutlineHeart } from "react-icons/ai";
import ReactGA from "react-ga4";

const StyledContainer = styled.div`
  margin-top: 12px;
  background-color: #fff;
  padding: 20px;
`;

const Title = styled.h2`
  font-weight: 700;
`;

const Title2 = styled.h6`
  display: none;
  font-weight: 800;

  @media (max-width: 768px) {
    display: block;
  }
`;

const StyledSpan = styled.span`
  display: none;

  @media (max-width: 768px) {
    display: inline-block;
  }
`;

const HorizontalLine = styled.hr`
  display: none;
  border: 0.5px solid #d5d5d5;
  margin-top: 20px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const AlphabetRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #ff2d55;
  font-size: 15px;
  font-weight: 600;

  & > span {
    margin-right: 15px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    color: black;
    font-weight: 700;

    & > span {
      margin-right: 15px;
      line-height: 30px;
    }
  }
`;

const FavoriteBrands = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    margin-top: 20px;
  }
`;

const SavedBrandsInfo = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #e3effc;
  padding: 10px;
  font-size: 12px;
`;

const AlphabetHeader = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const StyledList = styled.ul`
  padding-left: 0;
`;

const StyledListItem = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  list-style-type: none;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  color: #ff2d55;
  text-decoration: none;

  @media (max-width: 768px) {
    color: black;
  }
`;

const StyledIcon = styled.div`
  display: none;
  font-size: 20px;
  color: #9e9e9e;

  @media (max-width: 768px) {
    display: block;
  }
`;

const StyledListItemEmpty = styled.li`
  font-size: 13px;
  font-weight: 600;
  list-style-type: none;
`;

const BrandList = () => {
  const [selectedAlphabet, setSelectedAlphabet] = useState("");
  const [uniqueAlphabets, setUniqueAlphabets] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [brands, setBrands] = useState([]);

  const { data: { data: brandsData } = { brandsData: [] }, isLoading } =
    useFetchBrandsQuery();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Brand list page",
    });
  }, []);

  useEffect(() => {
    if (brandsData) {
      const uniqueAlphabets = [
        ...new Set(
          brandsData.map((brand) => brand?.name.charAt(0).toUpperCase())
        ),
      ];
      setUniqueAlphabets(uniqueAlphabets);
      setBrands(brandsData);
      setBrandList(brandsData);
    }

    if (selectedAlphabet && selectedAlphabet !== "#") {
      const filteredBrands = brands.filter((brand) =>
        brand?.name?.toLowerCase().startsWith(selectedAlphabet.toLowerCase())
      );
      setBrandList(filteredBrands);
      const uniqueAlphabets = [
        ...new Set(
          filteredBrands.map((brand) => brand?.name.charAt(0).toUpperCase())
        ),
      ];
      setUniqueAlphabets(uniqueAlphabets);
    } else {
      setBrandList(brands);
    }
  }, [selectedAlphabet, brands, brandsData]);

  const allAlphabets = [
    ...Array.from({ length: 26 }, (_, index) =>
      String.fromCharCode(65 + index)
    ),
    "#",
  ];

  if (isLoading) {
    return <Loading>Loading...</Loading>;
  }

  return (
    <StyledContainer>
      <Title>
        Brands <StyledSpan>A-Z</StyledSpan>
      </Title>
      <HorizontalLine />
      <Title2>Favorite Brands</Title2>
      <FavoriteBrands>
        <SavedBrandsInfo>
          <div>
            <AiOutlineHeart style={{ marginRight: "6px" }} />
          </div>
          <p>
            {" "}
            Save your Favorite Brands to your <a href="/">
              Beauty Prefrences
            </a>{" "}
            for a more personalized shopping experience.
          </p>
        </SavedBrandsInfo>
      </FavoriteBrands>
      <HorizontalLine />
      <Title2>All Brands</Title2>
      <HorizontalLine />
      <AlphabetRow>
        {allAlphabets.map((alphabet) => (
          <span key={alphabet} onClick={() => setSelectedAlphabet(alphabet)}>
            {alphabet}
          </span>
        ))}
      </AlphabetRow>
      <HorizontalLine />
      {uniqueAlphabets.map((alphabet) => (
        <div key={alphabet}>
          <AlphabetHeader>{alphabet}</AlphabetHeader>
          <StyledList>
            {brandList
              .filter((brand) =>
                brand?.name?.toLowerCase().startsWith(alphabet.toLowerCase())
              )
              .map((brand) => (
                <StyledListItem key={brand?.id}>
                  <StyledLink to={`/brand/${brand?.id}/user/5`}>
                    {brand?.name}
                  </StyledLink>
                  <StyledIcon>
                    <AiOutlineHeart />
                  </StyledIcon>
                </StyledListItem>
              ))}
          </StyledList>
        </div>
      ))}
      {brandList.length === 0 && (
        <StyledList>
          <AlphabetHeader>{selectedAlphabet}</AlphabetHeader>
          <StyledListItemEmpty>No brands found</StyledListItemEmpty>
        </StyledList>
      )}
    </StyledContainer>
  );
};

export default BrandList;
