import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { AiOutlineShopping } from "react-icons/ai";
import { APP_URL } from "../../constants/url";
import { Link } from "react-router-dom";

const StyledReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  margin-top: 10px;
  min-width: 250px;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
`;

const ProductPicture = styled(Link)`
  & > img {
    width: 50px;
    min-height: 50px;
  }
`;

const ProductDescription = styled.div`
  font-size: 12px;
`;

const ProductButtons = styled.div`
  padding-top: 10px;
`;

const BrandName = styled(Link)`
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductName = styled(Link)`
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ReviewCount = styled.div`
  font-size: 11px;
  margin-bottom: 10px;
`;

const ReviewButton = styled(Button)`
  color: white;
  background-color: #ff2d55;
  border: 1px solid;
  padding-left: 26px;
  padding-right: 36px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 12px 12px 12px 12px;
  font-size: 12px;
`;

const ShoppingButton = styled(Button)`
  border: 1px solid;
  margin-right: 10px;
  background-color: white;
  cursor: pointer;
  border-color: #ff2d55;
  color: #ff2d55;
  border-radius: 12px 12px 12px 12px;
`;

const ReviewCard = ({ productData, user_id = 5 }) => {
  return (
    <StyledReviewCard>
      <ProductDescription>
        <BrandName to={`/brand/${productData?.brand_id}/user/${user_id}`}>
          {productData?.brand?.name}
        </BrandName>
        <ProductName to={`/product/${productData?.id}/user/${user_id}`}>
          {productData?.name}
        </ProductName>
        <ReviewCount>{productData?.review_count} Reviews</ReviewCount>
      </ProductDescription>
      <ProductPicture to={`/product/${productData?.id}/user/${user_id}`}>
        <img src={productData.images?.[0]} alt={"no img"} />
      </ProductPicture>
      <ProductButtons>
        <ShoppingButton href={APP_URL} target="_blank" variant="danger">
          <AiOutlineShopping />
        </ShoppingButton>
        <ReviewButton href={APP_URL} target="_blank" variant="danger">
          Review
        </ReviewButton>
      </ProductButtons>
    </StyledReviewCard>
  );
};

export default ReviewCard;
